// src/components/Services.js
import React from 'react';
import Page from './Page';
import image1 from '../assets/bogføring.jpg';
import image2 from '../assets/årsregnskab.jpg';
import image3 from '../assets/udkørsel.jpg';

const Service0 = () => {
  const title = "Grundpakke: Bogføring";
  const subtitle = "Effektiv og overskuelig bogføring for din virksomhed";
  const text = `Vores Grundpakke er designet til at tage hånd om din virksomheds bogføring på en effektiv og overskuelig måde. Med denne pakke får du:

  - Bogføring af op til 40 bilag om måneden, så du kan holde orden i dit regnskab.
  - Momsregnskab, der sikrer, at din virksomhed overholder alle regler og krav omkring moms.
  - Kvartalsregnskab, der giver dig et klart overblik over din virksomheds økonomiske situation løbende.

  Skulle du have brug for at bogføre flere bilag end de inkluderede 40, koster det kun 50 kr ekstra for hver 10. bilag.

  Vælg vores Grundpakke og oplev en problemfri bogføring, der giver dig mere tid til at fokusere på det, der virkelig betyder noget for din virksomhed.`;

  return (
    <Page
      title={title}
      subtitle={subtitle}
      text={text}
      image={image1}
    />
  );
};

const Service1 = () => {
  const title = "Årsopgørelse: Årsregnskab";
  const subtitle = "Komplet årsregnskab og selvangivelse for din virksomhed";
  const text = `Vores Årsopgørelse service sørger for, at din virksomheds årsregnskab og selvangivelse er i orden og overholder alle gældende regler. Med denne service får du:

  - Opstilling af årsregnskab, der giver dig et klart overblik over din virksomheds økonomiske præstationer.
  - Indberetning af årsregnskab, så du opfylder alle lovkrav og frister.
  - Opgørelse af skattepligtig indkomst, så du ved præcis, hvad din virksomhed skal betale i skat.
  - Assistance med årsafslutningsposteringer, herunder årlige afskrivninger, der hjælper dig med at holde dit regnskab ajour.
  - Gennemgang af bogholderi og saldobalance, så du er sikker på, at alt stemmer og er korrekt.
  - Indberetning af selvangivelse, så du overholder frister og regler for skattebetaling.

  For kun 1.999 kr får du en komplet Årsopgørelse service, der sikrer, at din virksomheds økonomi er i gode hænder.`;

  return (
    <Page
      title={title}
      subtitle={subtitle}
      text={text}
      image={image2}
    />
  );

};

const Service2 = () => {
  const title = "Udkørende Konsulent: Personlig rådgivning";
  const subtitle = "Få professionel rådgivning direkte hos dig";
  const text = `Vores Udkørende Konsulent service tilbyder personlig og målrettet rådgivning for din virksomhed. Med denne service får du:

  - En professionel konsulent, der besøger din lokation for at forstå dine forventninger og behov.
  - Skræddersyet rådgivning baseret på din virksomheds specifikke situation og mål.
  - Mulighed for at stille spørgsmål og få svar på eventuelle bekymringer.
  - Konkrete anbefalinger til, hvordan du kan forbedre din virksomheds økonomiske præstationer.

  Det bedste af det hele er, at denne service er helt gratis. Gør brug af vores ekspertise og lad os hjælpe dig med at optimere din virksomheds økonomi.`;

  return (
    <Page
      title={title}
      subtitle={subtitle}
      text={text}
      image={image3}
    />
  );
};

export { Service0, Service1, Service2 };
