import React from 'react';
import { Box, Grid } from '@mui/material';
import ServiceCard from './ServiceCard';
import bogføring from '../assets/bogføring.jpg';
import årsregnskab from '../assets/årsregnskab.jpg';
import heroImage from '../assets/hero-image.jpg';
import udkørsel from '../assets/udkørsel.jpg';


// Replace these with the actual images you want to use
const image1 = bogføring;
const image2 = årsregnskab;
const image3 = udkørsel;

const services = [
  {
    title: 'Bogføring',
    description: 'Vi tager os af din daglige bogføring, så du kan fokusere på din virksomheds vækst.',
    image: image1,
    price: "499 kr"
  },
  {
    title: 'Årsregnskab',
    description: 'Vi udarbejder årsregnskaber og sikrer, at alt er i overensstemmelse med gældende regler.',
    image: image2,
    price: "1999 kr"
  },
  {
    title: 'Udkørende Konsulent',
    description: 'Få professionel konsulent til at køre du til din lokation for at høre dine forventninger.',
    image: image3,
    price: "GRATIS"
  },
];


function ServicesSection() {
  return (
    <Box my={1}>
      <Grid container spacing={1}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ServiceCard
              image={service.image}
              title={service.title}
              description={service.description}
              price={service.price} // Pass the index (incremented by 1) to ServiceCard
              index={index}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ServicesSection;
