import React from 'react';
import HeroSection from './Components/HeroSection';
import ServicesSection from './Components/ServicesSection';
import TextSection from './Components/TextSection';
import Footer from './Components/Footer';
import TeamSection from './Components/TeamSection';
import PricingSection from './Components/PricingSection';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Service0, Service1, Service2 } from './Components/Services';



function HomePage() {
  return (
    <div>
      <HeroSection />
      <ServicesSection />
      <TextSection />
      <PricingSection />
      <TeamSection />
      <Footer />
    </div>
  );
}


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/service0" element={<Service0 />} />
        <Route path="/service1" element={<Service1 />} />
        <Route path="/service2" element={<Service2 />} />
      </Routes>
    </Router>
  );
}

export default App;
