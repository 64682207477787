import React, {useState} from 'react';
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { styled } from '@mui/system';
import FormDialog from './FormDialog';
import textImg from '../assets/textImg.jpg';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';



const TextSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '5rem 0',
  [theme.breakpoints.down('xs')]: {
    padding: 0,
  },
}));

const TextContainer = styled(Grid)(({ theme }) => ({
  width: '60%',
  margin: '0 auto',
  [theme.breakpoints.down('xs')]: {
    margin: 0,
  },
}));

const Text = styled(Typography)({
  fontSize: '1.25rem',
  color: '#808080',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1.25rem',
  color: '#2F2F2F',
});

const Subtitle = styled(Typography)({
  fontSize: '2.5rem',
  color: '#2F2F2F',
});

const ButtonContainer = styled(Box)({
  marginTop: '2rem',
});

const ActionButton = styled(Button)({
  backgroundColor: '#eb7d74',
  color: 'white',
  borderRadius: '30px',
  padding: '1rem 2rem',
  fontWeight: 'bold',
});

function TextSection() {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);

  const [form, setForm] = useState({
    firmanavn: '',
    cvr: '',
    email: '',
    telefonnummer: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = validateForm(form);

    if (Object.keys(newErrors).length === 0) {
      // No errors, proceed with form submission
      const formData = new FormData(event.target);

      fetch('https://getform.io/f/6d4136c2-3d2b-47db-aa01-84ae09aa9e1c', {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            alert('Form submitted successfully!');
          } else {
            alert('Something went wrong. Please try again later.');
          }
        })
        .catch((error) => {
          console.error('Error submitting form:', error);
          alert('Something went wrong. Please try again later.');
        });

    } else {
      // Set errors to display error messages
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.firmanavn) {
      newErrors.firmanavn = 'Firmanavn er påkrævet';
    }

    if (!form.cvr || isNaN(form.cvr)) {
      newErrors.cvr = 'CVR skal være et tal';
    }

    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Ugyldig email';
    }

    if (!form.telefonnummer || isNaN(form.telefonnummer)) {
      newErrors.telefonnummer = 'Telefonnummer skal være 8 cifre';
    }

    return newErrors;
  };


  return (
    <TextSectionContainer id="omOs">
      <TextContainer container spacing={0} justifyContent="space-between">
        <Grid item xs={12} sm={6} sx={{padding: {xs:'0px'}}}>
          <Title variant="h6">Hvad vi tilbyder</Title>
          <Subtitle variant="h4">
            {isSmallScreen
              ? 'Forpligtet til vækst og succes gennem nyskabelse, samarbejde og engagement'
              : 'Forpligtet til vækst og succes gennem nyskabelse, samarbejde og engagement. Vi tror på, at nyskabelse, samarbejde og engagement er nøglen til at opnå dette mål.'}
          </Subtitle>
          <Text>
            {isSmallScreen
              ? 'At finde de rette samarbejdspartnere er afgørende for virksomheders økonomiske succes. Vores kompetente konsulenter leverer en række tjenester, der støtter dig i at skabe en solid økonomisk fremtid for din forretning. Tidlig og effektiv vejledning hjælper med at forhindre problemer med skattevæsenet.'
              : 'At finde de rette samarbejdspartnere er afgørende for virksomheders økonomiske succes, da et godt samarbejde kan føre til øget produktivitet og større overskud. Vores erfarne og kompetente konsulenter er her for at hjælpe dig med at finde de rigtige løsninger og sikre din virksomheds fremtidige vækst og succes. Vi tilbyder en bred vifte af tjenester, der kan støtte dig i alt fra strategisk planlægning til finansiel rådgivning og bogføring. Vores tidlige og effektive vejledning kan hjælpe med at forhindre problemer med skattevæsenet og minimere dine omkostninger på lang sigt.'}
          </Text>
        </Grid>

        <Grid item xs={12} sm={6}>
          {!isSmallScreen && (
              <img
                width="100%"
                style={{ marginLeft: '50px', borderRadius: '10px' }}
                src={textImg}
              />
            )}
          <form id="contactForm"  action="https://getform.io/f/74393616-a1aa-4b03-a842-d07efc5b2701" method="POST" onSubmit={handleSubmit} style={{marginLeft: '10%',marginTop: '1rem'}} >
            <TextField
              label="Firmanavn"
              name="firmanavn"
              value={form.firmanavn}
              onChange={handleChange}
              error={!!errors.firmanavn}
              helperText={errors.firmanavn}
              style={{marginTop: '1rem'}}
              fullWidth
            />

            {/* add hidden Honeypot input to prevent spams */}
            <input type="hidden" name="_gotcha" style={{display:"none !important"}} />

            <TextField
              label="CVR"
              name="cvr"
              type="number"
              value={form.cvr}
              onChange={handleChange}
              error={!!errors.cvr}
              helperText={errors.cvr}
              style={{marginTop: '1rem'}}
              fullWidth
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              style={{marginTop: '1rem'}}
              fullWidth
            />
            <TextField
              label="Telefonnummer"
              name="telefonnummer"
              value={form.telefonnummer}
              onChange={handleChange}
              error={!!errors.telefonnummer}
              helperText={errors.telefonnummer}
              style={{marginTop: '1rem'}}
              fullWidth
            />
              <ActionButton style={{marginTop:"5%"}} type="submit">Send</ActionButton>
          </form>

        </Grid>

      </TextContainer>
      <ButtonContainer>
      </ButtonContainer>
    </TextSectionContainer>
  );
}

export default TextSection;
