import React, { useState, useEffect } from 'react';
import { TextField, DialogActions, Button } from '@mui/material';
// src/components/NavBar.js
import { AppBar, Toolbar, Typography, Container, Grid, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';
import { useNavigate } from 'react-router-dom';


const underlineAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'flex-start',
  position: 'absolute',
  left: '4%',
  '& a': {
    textDecoration: 'none',
    marginRight: '1rem',
    fontSize: '0.8rem',
    position: 'relative',
    '&:hover::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: '2px solid black',
      animation: `${underlineAnimation} 0.5s linear forwards`,
    },
  },
});


function Page({title, subtitle, text, image}) {

	const navigate = useNavigate();

	const [form, setForm] = useState({
	    firmanavn: '',
	    cvr: '',
	    email: '',
	    telefonnummer: '',
	 });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = validateForm(form);

    if (Object.keys(newErrors).length === 0) {
      // No errors, proceed with form submission
      const formData = new FormData(event.target);

      fetch('https://getform.io/f/6d4136c2-3d2b-47db-aa01-84ae09aa9e1c', {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            alert('Form submitted successfully!');
          } else {
            alert('Something went wrong. Please try again later.');
          }
        })
        .catch((error) => {
          console.error('Error submitting form:', error);
          alert('Something went wrong. Please try again later.');
        });

    } else {
      // Set errors to display error messages
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.firmanavn) {
      newErrors.firmanavn = 'Firmanavn er påkrævet';
    }

    if (!form.cvr || isNaN(form.cvr)) {
      newErrors.cvr = 'CVR skal være et tal';
    }

    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Ugyldig email';
    }

    if (!form.telefonnummer || isNaN(form.telefonnummer)) {
      newErrors.telefonnummer = 'Telefonnummer skal være 8 cifre';
    }

    return newErrors;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>
  		<AppBar position="relative" color="transparent" elevation={0}>
	      <StyledToolbar>
	        <IconButton
	          edge="start"
	          color="inherit"
	          aria-label="back"
	          onClick={() => navigate(`/`)}
	        >
	          <ArrowBackIcon color="black" />
	        </IconButton>
	        <Typography
	          component="a"
	          variant="h6"
	          href="#home"
	          color="black"
	          onClick={() => navigate(`/`)}
	        >
	          Tilbage
	        </Typography>
	      </StyledToolbar>
	    </AppBar>
  	   <Container maxWidth="lg" sx={{ marginTop: { xs: '10%', md: '2%' } }}>
	      <Grid container spacing={2}>
	        <Grid item xs={12} md={4}>
				<h1>{title}</h1>
				<h2>{subtitle}</h2>
				<p>{text}</p>
			  	<form id="contactForm"  action="https://getform.io/f/74393616-a1aa-4b03-a842-d07efc5b2701" method="POST" onSubmit={handleSubmit} style={{marginTop: '1rem'}} >
			    	<TextField
				        label="Firmanavn"
				        name="firmanavn"
				        value={form.firmanavn}
				        onChange={handleChange}
				        error={!!errors.firmanavn}
				        helperText={errors.firmanavn}
				        style={{marginTop: '1rem'}}
				        fullWidth
				    />

			      	{/* add hidden Honeypot input to prevent spams */}
			      	<input type="hidden" name="_gotcha" style={{display:"none !important"}} />

					<TextField
						label="CVR"
						name="cvr"
						type="number"
						value={form.cvr}
						onChange={handleChange}
						error={!!errors.cvr}
						helperText={errors.cvr}
						style={{marginTop: '1rem'}}
						fullWidth
					/>
					<TextField
						label="Email"
						name="email"
						type="email"
						value={form.email}
						onChange={handleChange}
						error={!!errors.email}
						helperText={errors.email}
						style={{marginTop: '1rem'}}
						fullWidth
					/>
					<TextField
						label="Telefonnummer"
						name="telefonnummer"
						value={form.telefonnummer}
						onChange={handleChange}
						error={!!errors.telefonnummer}
						helperText={errors.telefonnummer}
						style={{marginTop: '1rem'}}
						fullWidth
					/>
					<Button type="submit">Send</Button>
			    </form>
			    </Grid>
		        <Grid item xs={12} md={8}>
		          <img src={image} alt={title} style={{ width: '100%', height: 'auto' }} />
		        </Grid>
		    </Grid>
		</Container>
  </>;
}

export default Page;