import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormHelperText,
} from '@mui/material';


function FormDialog({ open, handleClose }) {
  const [form, setForm] = useState({
    firmanavn: '',
    cvr: '',
    email: '',
    telefonnummer: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = validateForm(form);

    if (Object.keys(newErrors).length === 0) {
      // No errors, proceed with form submission
      const formData = new FormData(event.target);

      fetch('https://getform.io/f/6d4136c2-3d2b-47db-aa01-84ae09aa9e1c', {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            alert('Tak for din interesse. Vi kontakter dig hurtigst muligt!');
          } else {
            alert('Something went wrong. Please try again later.');
          }
        })
        .catch((error) => {
          console.error('Error submitting form:', error);
          alert('Something went wrong. Please try again later.');
        });

      handleClose();
    } else {
      // Set errors to display error messages
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.firmanavn) {
      newErrors.firmanavn = 'Firmanavn er påkrævet';
    }

    if (!form.cvr || isNaN(form.cvr)) {
      newErrors.cvr = 'CVR skal være et tal';
    }

    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Ugyldig email';
    }

    if (!form.telefonnummer || isNaN(form.telefonnummer)) {
      newErrors.telefonnummer = 'Telefonnummer skal være 8 cifre';
    }

    return newErrors;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Få et uforpligtende tilbud</DialogTitle>
      <DialogContent>
        <form id="contactForm"  action="https://getform.io/f/74393616-a1aa-4b03-a842-d07efc5b2701" method="POST" onSubmit={handleSubmit} style={{marginTop: '1rem'}} >
          <TextField
            label="Firmanavn"
            name="firmanavn"
            value={form.firmanavn}
            onChange={handleChange}
            error={!!errors.firmanavn}
            helperText={errors.firmanavn}
            style={{marginTop: '1rem'}}
            fullWidth
          />

          {/* add hidden Honeypot input to prevent spams */}
          <input type="hidden" name="_gotcha" style={{display:"none !important"}} />

          <TextField
            label="CVR"
            name="cvr"
            type="number"
            value={form.cvr}
            onChange={handleChange}
            error={!!errors.cvr}
            helperText={errors.cvr}
            style={{marginTop: '1rem'}}
            fullWidth
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={form.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            style={{marginTop: '1rem'}}
            fullWidth
          />
          <TextField
            label="Telefonnummer"
            name="telefonnummer"
            value={form.telefonnummer}
            onChange={handleChange}
            error={!!errors.telefonnummer}
            helperText={errors.telefonnummer}
            style={{marginTop: '1rem'}}
            fullWidth
          />
          <DialogActions>
            <Button onClick={handleClose}>Annuller</Button>
            <Button type="submit">Send</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default FormDialog;
