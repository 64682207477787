import React, { useState } from 'react';
import { Typography, Button, Box, Card } from '@mui/material';
import { styled } from '@mui/system';
import backgroundImage from '../assets/PriceCard.png';
import { ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormDialog from './FormDialog';


const StyledPricingSection = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 'auto',
  minHeight: '90vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const CallToActionButton = styled(Button)({
  color: 'white',
  backgroundColor: 'transparent',
  border: 'solid 2px white',
  padding: '1rem 2rem',
  borderRadius: '30px',
  fontWeight: 'bold',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  marginTop: '1rem',
  '&:hover': {
    backgroundColor: '#424345',
  },
});

const TransparentCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(135deg, rgb(55, 34, 84) 0%, rgb(254, 161, 155) 20%, rgba(128, 106, 219, 0.63) 100%)",
  backdropFilter: 'blur(4px)',
  padding: '2rem',
  borderRadius: 25,
  margin: 19,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '60vh', // Set the minimum height you want
  height: '65vh',
  maxWidth: '350px',
  [theme.breakpoints.down('sm')]: {
    margin: '2rem',
    height: 'fit-content',
    width: '80vw',

  },
}));

const PriceBelt = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '80px', // Set the minimum height you want for the belt
  background: 'linear-gradient(135deg, rgba(91, 134, 229, 0.85) 0%, rgba(128, 106, 219, 0.85) 100%)',
  borderRadius: '0 0 25px 25px',
  marginBottom: '1rem',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-20px',
    left: 0,
    width: '50%',
    height: '20px',
    background: 'linear-gradient(135deg, rgba(91, 134, 229, 0.85) 0%, rgba(128, 106, 219, 0.85) 100%)',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-20px',
    right: 0,
    width: '50%',
    height: '20px',
    background: 'linear-gradient(135deg, rgba(91, 134, 229, 0.85) 0%, rgba(128, 106, 219, 0.85) 100%)',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
}));


const ListContainer = styled(Box)({
  border: '2px solid white',
  borderRadius: '15px',
  padding: '1rem',
  marginBottom: '1rem',
});


const PriceButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});


const TextOutline = styled(Typography)({});
const TextOutlineGratis = styled(Typography)(({ theme, color, fontSize }) => ({
  // ...existing styles...
  fontSize: fontSize, // Use provided fontSize or 'inherit' if not provided
}));
function PricingSection() {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledPricingSection id="tjenester" >
      <CardContainer>

        <TransparentCard>
          <TextOutline variant="h3" style={{maxWidth: "100vw"}} color="white" fontWeight="bold">
            Udkørende Konsulent
          </TextOutline>
          {/*<TextOutline variant="body2" color="white" fontWeight="bold" fontStyle="italic">
            Basic Info: Your Subtitle Here
          </TextOutline>*/}

          <Box display="flex" alignItems="center" mt={1}>
            <ListContainer>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Konsulent møder op uforpligtende på din lokation for at høre dine forventninger.</TextOutline>
                </ListItemText>
              </Box>
              {/* Add more list items */}
            </ListContainer>
          </Box>

          <PriceButtonContainer>
            <TextOutlineGratis fontSize="1.8rem" color="white" fontWeight="bold" mt={2}>
              Uforpligtende Samtale 
            </TextOutlineGratis>
            <CallToActionButton variant="contained" onClick={handleClickOpen}>
              Bliv ringet op
            </CallToActionButton>
          </PriceButtonContainer>
        </TransparentCard>

        <TransparentCard>
          {/* Existing package */}
          <TextOutline variant="h3" color="white" style={{maxWidth: "100vw"}} fontWeight="bold">
            Grundpakke
          </TextOutline>
          <TextOutline variant="body2" color="white" fontWeight="bold" fontStyle="italic">
            (Månedlig Ydelse)
          </TextOutline>

          <Box display="flex" alignItems="center" mt={1}>
            <ListContainer>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >40 bilag</TextOutline>
                </ListItemText>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Momsregnskab</TextOutline>
                </ListItemText>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Kvartalsregnskab</TextOutline>
                </ListItemText>
              </Box>
              <Box display="flex" alignItems="center" mt={3}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white"  fontWeight="bold" >
                    50 kr yderligere for hver 10. bilag ekstra.
                  </TextOutline>
                </ListItemText>
              </Box>
            </ListContainer>
          </Box>

          <PriceButtonContainer>
            <TextOutline variant="h3" color="white" fontWeight="bold" mt={2}>
              499 kr
            </TextOutline>
            <CallToActionButton variant="contained" onClick={handleClickOpen}>
              Bliv ringet op
            </CallToActionButton>
          </PriceButtonContainer>

        </TransparentCard>



        <TransparentCard>
          {/* New package */}
          <TextOutline variant="h3" color="white" style={{fontSize: "2.5rem"}} fontWeight="bold">
            Årsopgørelse{' '}
          </TextOutline>
          <TextOutline component="span" variant="body2" color="white" fontWeight="bold">
            (Engangsbetaling)
          </TextOutline>
          
          <Box display="flex" alignItems="center" mt={1}>
            <ListContainer>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Opstilling af årsregnskab</TextOutline>
                </ListItemText>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Indberetning af årsregnskab</TextOutline>
                </ListItemText>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Opgørelse af skattepligtig indkomst</TextOutline>
                </ListItemText>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" > Assistance med årsafslutningsposteringer <br/> (årlig afskrivninger)</TextOutline>
                </ListItemText>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Gennemgang af bogholderi og saldobalance</TextOutline>
                </ListItemText>
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Indberetning af selvangivelse</TextOutline>
                </ListItemText>
              </Box>
            </ListContainer>
          </Box>


          <PriceButtonContainer>
            <TextOutline variant="h3" color="white" fontWeight="bold" mt={2}>
              fra 1999 kr
            </TextOutline>
            <CallToActionButton variant="contained" onClick={handleClickOpen}>
              Bliv ringet op
            </CallToActionButton>
          </PriceButtonContainer>


        </TransparentCard>

        <FormDialog open={open} handleClose={handleClose} />

      </CardContainer>
    </StyledPricingSection>
  );
}

export default PricingSection;
